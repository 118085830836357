// @flow
import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { rhythm } from '../utils/typography';
import Container from '../components/Container';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Feature from '../components/Feature';
import Footer from '../components/Footer';
// import AppStoreButton from '../components/AppStoreButton';
import Intro from '../components/Intro';
import KeyHighlight from '../components/KeyHighlight';
import IPhone from '../components/IPhone';
import demoMovie from '../images/demo.mp4';

const campusImage = require('../images/campusbord.jpg');
const utrechtImage =
  'https://storage.googleapis.com/planl-zorg.appspot.com/images/studentenarts-utrecht/uithof.jpg';
const richtersImage = require('./huisartspraktijk-richters-enschede/gezondheidshoes.jpg');
const wandIcon = require('../images/lock.png');
const lockIcon = require('../images/wand.png');
const speedIcon = require('../images/speed.png');

const TABLET = '@media (max-width: 768px)';

const ProviderCard = ({ image, to, title, description }) => (
  <Link
    css={{
      display: 'block',
      overflow: 'hidden',
      borderRadius: 3,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      width: '50%',
      boxSizing: 'border-box',
      height: 250,
      [TABLET]: {
        width: '100%'
      }
    }}
    to={to || '/'}
  >
    <div
      css={{
        position: 'relative',
        width: '100%',
        height: '100%',
        boxShadow: '1px 1px 20px 0 rgba(0,0,0,0.15)',
        borderRadius: 3,
        transition: 'transform .35s ease-in-out',
        ':hover': {
          transform: 'scale(0.95)',
          '#scale-up-on-hover': {
            transform: 'scale(1.1)'
          }
        },
        img: {
          objectFit: 'cover !important',
          objectPosition: 'center center !important',
          fontFamily: "'object-fit: cover !important; object-position: center center !important;'"
        }
      }}
    >
      <img
        alt=""
        id="scale-up-on-hover"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          height: 250,
          width: '100%',
          transition: 'transform .4s ease-in-out'
        }}
        src={image}
      />

      <div
        id="scale-up-on-hover"
        css={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, .25), rgba(0,0,0,0))`,
          zIndex: 2,
          transition: 'transform .4s ease-in-out'
        }}
      />
      <div
        css={{
          position: 'absolute',
          left: '1rem',
          bottom: 0,
          color: 'white',
          textShadow: '1px 2px 5px rgba(0,0,0,0.65)',
          pointerEvents: 'none',
          zIndex: 3
        }}
      >
        <h2
          css={{
            color: 'white',
            margin: 0,
            marginBottom: '.5rem'
          }}
        >
          {title}
        </h2>
        <p>{description}</p>
      </div>
    </div>
  </Link>
);

const head = (
  <Helmet
    title="Afspraak maken met je huisarts | Planl"
    meta={[
      //       {
      //         name: 'apple-itunes-app',
      //         content: 'app-id=1240303108, affiliate-data=planl.nl, app-argument=planl.nl'
      //       },
      {
        name: 'description',
        content:
          'De beste oplossing voor online afspraken. Planl maakt het contact tussen zorgverlener en patiënt makkelijker, gebruiksvriendelijker en toegankelijker voor zowel de zorgverlener als de patiënt.'
      },
      {
        name: 'keywords',
        content:
          'Afspraak maken,Campus UT huisartsenpraktijk,Campushuisarts,Enschede,Saxion,Huisarts UT,Huisarts Saxion'
      }
    ]}
  />
);

const navbar = (
  <Navbar>
    <Navbar.Header>Planl</Navbar.Header>
    <Navbar.Menu>
      <Navbar.Menu.Item>
        <Link to="/info">Voor zorgverleners</Link>
      </Navbar.Menu.Item>
    </Navbar.Menu>
  </Navbar>
);

// <p css={{ marginTop: '1em' }}>Download de app voor een nog betere ervaring.</p>
//
// <AppStoreButton.iOS page="Homepage" />
// <AppStoreButton.Android page="Homepage" />
const hero = (
  <Hero>
    <div css={{ maxWidth: 512 }}>
      <h1 css={{ lineHeight: 1.5 }}>Eenvoudig een afspraak met je zorgverlener</h1>

      <p>
        Planl maakt het contact tussen zorgverlener en patiënt makkelijker, gebruiksvriendelijker en
        toegankelijker voor zowel de zorgverlener als de patiënt.
      </p>
    </div>
  </Hero>
);

const iphoneDemo = (
  <IPhone>
    <video
      autoPlay
      loop
      muted
      css={{
        height: '100%'
      }}
    >
      <source src={demoMovie} type="video/mp4" />
    </video>
  </IPhone>
);

const PatientLandingPage = () => (
  <div>
    {head}

    {navbar}

    {hero}

    <Container customCSS={{ paddingTop: '2rem' }}>
      <h2>Maak een afspraak bij</h2>
      <div
        css={{
          display: 'flex',
          'a:not(:first-child)': { marginLeft: rhythm(1) },
          [TABLET]: {
            flexDirection: 'column',
            'a:not(:first-child)': { marginLeft: 0, marginTop: rhythm(1) }
          }
        }}
      >
        <ProviderCard
          image={campusImage}
          to="/campus-huisarts-enschede"
          title="Campus UT Huisartsenpraktijk"
          description="Universiteit Twente & Saxion"
        />
        <ProviderCard
          image={utrechtImage}
          to="/studentenarts-utrecht"
          title="Studentenarts Utrecht"
          description="Op Janskerkhof en de Uithof"
        />
        <ProviderCard
          image={richtersImage}
          to="/huisartspraktijk-richters-enschede"
          title="Huisartspraktijk Richters"
          description="Gezondheidshoes Diekman"
        />
      </div>
    </Container>

    <Container>
      <Intro>
        Geen bandje meer inspreken tussen 8.00 en 8.30. <br />
        Geen flash website die niet werkt op je telefoon. <br />
        Direct digitaal een afspraak maken, wanneer jij dat wilt.
      </Intro>

      <KeyHighlight.Group>
        <KeyHighlight
          icon={wandIcon}
          title="Eenvoudig"
          content={`Geen bandje meer inspreken tussen 8.00 en 8.30. Geen flash website die niet werkt op je telefoon.\nDirect digitaal een afspraak maken, wanneer jij dat wilt.`}
        />
        <KeyHighlight
          icon={lockIcon}
          title="Veilig"
          content="Veiligheid is prioriteit nummer 1 bij Planl. Alleen je eigen arts kan bij jouw gegevens."
        />
        <KeyHighlight
          icon={speedIcon}
          title="Snel"
          content={`Een account aanmaken kost nog geen 30 seconden, daarna kun je deze gebruiken bij al je zorgverleners.\nKies zelf waar, wanneer en door wie je geholpen wil worden.`}
        />
      </KeyHighlight.Group>

      <Feature
        title="Een afspraak maken"
        description="Kies heel gemakkelijk waar, wanneer en bij wie je een afspraak wil."
        textStyle={{ textAlign: 'center', marginBottom: 50, maxWidth: 300 }}
        image={iphoneDemo}
      />
    </Container>
    <Footer />
  </div>
);

export default PatientLandingPage;
